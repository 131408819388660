* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

html,
body {
  height: 100%;
}

.container {
  max-width: 1320px; /*1260 + 30 + 30*/
  padding: 0 30px;
  margin: 0 auto;
}
